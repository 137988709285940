import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link } from "gatsby"

const SignUpSuccessPage = () => (
  <Layout>
    <Header />
      <div className="master-container">
          <main role="main">
            <div className="d-flex flex-column justify-content-center align-items-center fadein-on-load">
                <div className="d-flex align-items-center mb-2 mb-sm-4">
                  <div id="crafted-logo-container" className="success">
                    <img src="/assets/images/crafted_logo.png" alt="Join Crafted Logo" />
                  </div>
                </div>
                <div className="text-center">
                  <h2 className="my-2 my-sm-4">Thanks for joining us!</h2>
                  <p className="mb-2 mb-sm-4">You should receive a confirmation email within a few minutes.</p>
                </div>
                <p className="mt-2 mt-sm-4 text-small"><Link className="text-dark-blue" to="/001-duncans-brewing">Read the first edition</Link></p>
            </div>
          </main>
        </div>
    <Footer />
  </Layout>
);

export default SignUpSuccessPage;
